.headerContainer {
  min-height: 60px;
  max-height: 60px;

  background-color: #fff;

  color: var(--main-bg);

  display: flex;
  align-items: center;
  flex: 1;

  &.full {
    min-width: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }

  &.minimized {
    min-width: calc(100% - 420px);
    max-width: calc(100% - 420px);
  }

  @media (max-width: 968px) {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

.title {
  padding: 5px 30px;
  margin-right: 20px;
  height: 100%;
  background-color: #005BBC;
  color: #FFD633;

  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none !important;

  @media (max-width: 968px) {
    font-size: 10px;
    margin-right: 7px;
    padding: 5px 10px;
  }
}

.subtitle {
  color: #005BBC;
  margin-right: 20px;
  font-size: 12px;
  letter-spacing: 0.5px;

  strong {
    color: rgba(0, 0, 0, .5);
  }

  @media (max-width: 968px) {
    font-size: 10px;
    margin-right: 7px;
  }
}

.eventStartDate {
  font-size: 14px;
  font-weight: 600;
  margin-left: auto;
  padding: 5px 15px;
  min-width: 200px;

  display: flex;
  align-items: center;
}
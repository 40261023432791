.container {
  position: absolute;

  top: 40px;
  right: 105px;

  // width: 275px;
  min-height: 36px;
  max-height: 36px;
  // max-height: 170px;

  z-index: 99;

  color: #fff;

  text-align: right;

  @media (max-width: 968px) {
    display: none;
  }

  .buttonContainer {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 5px 3px;
    display: inline-flex;
  }

  .innerContainer {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 15px;
    text-align: left;

    > span {
      display: block;
      font-size: 10px;
      margin-bottom: 10px;
      text-transform: uppercase;
      letter-spacing: 0.3px;

      opacity: 0.75;
    }

    > ul {
      font-size: 10px;
      margin: 0 0 10px 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin: 3px 0;

      > strong {
        text-transform: uppercase;
      }
    }
  }
}

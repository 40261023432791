.sidebarContainer {
  min-height: 100%;
  max-height: 100%;

  background-color: var(--main-bg);
  flex: 1;

  overflow-y: auto;

  position: relative;
  // border-left: 1px solid var(--main-bg);

  &.open {
    min-width: 420px;
    max-width: 420px;
  }

  &.closed {
    min-width: 50px;
    max-width: 50px;

    .title > span {
      display: none;
    }

    .title .iconWrapper {
      transform: rotate(0deg);
    }

    .markerDisplay {
      display: none;
    }
  }

  @media (max-width: 968px) {
    border-bottom: 1px solid var(--main-accent);

    min-width: 100%;
    max-width: 100%;

    &.open,
    &.closed {
      min-width: 100%;
      max-width: 100%;
    }

    &.open {
      min-height: 35%;
      max-height: 35%;
    }

    &.closed {
      min-height: 60px;
      max-height: 60px;

      .title > span {
        display: flex;
      }
    }
  }
}

.menuPlaceholder {
  font-size: 12px;
  margin-top: 20px;
  color: #fff;
  text-align: center;

  > div {
    height: 20px;
  }
}

.title {
  height: 60px;
  background-color: #fff;
  padding: 5px 15px;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  position: sticky;
  top: 0;
  z-index: 99;

  > .selection {
    width: 80%;

    > div {
      overflow: hidden;
      background-color: #fff;

      svg {
        background-color: #fff;
        right: 0;
      }
    }
  }

  > span {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .iconWrapper {
    line-height: 12px;
    transform: rotate(180deg);
    color: #005bbc;
  }

  @media (max-width: 968px) {
    border-top: 1px solid var(--main-bg);
    font-size: 10px;
    height: 60px;
  }
}

.content {
  overflow-y: auto;
  color: #fff;

  .text {
    display: block;
    padding: 10px 15px 0 15px;

    > p {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  .listItem {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    
    .iconRoot {
      min-width: 24px;
    }

    p {
      font-size: 12px;
      color: #fff;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    span {
      font-size: 12px;
    }
  }

  .selected,
  .selected:hover,
  .listItem:hover {
    background-color: rgba(0, 91, 188, 0.5) !important;
  }

  button {
    font-size: 10px;
    color: #fff;
  }

  .noData {
    padding: 10px;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
  }
}

.markerDisplay {
  color: #fff;
  padding: 10px 0 0 0;

  display: flex;

  span {
    font-size: 12px;
  }

  .checkboxRoot {
    padding: 4px;
    color: #f50057;
  }
}

.pilotColor {
  width: 14px;
  height: 14px;
  border-radius: 100%;
}

.noSelectedEvent {
  font-size: 12px;
  color: #fff;
  padding: 10px;

  min-height: calc(100% - 80px);
  max-height: calc(100% - 80px);

  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  // border-top: 1px solid rgba(255, 255, 255, 0.3);
  height: 30px;
  font-size: 12px;
  color: var(--main-accent);
  background-color: var(--main-bg);
  padding: 3px 15px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 968px) {
    display: none;
  }
}

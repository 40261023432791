@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --main-bg: #01012b;
  --main-accent: #FFD633;
  --main-accent-rgb: 92, 211, 129;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

.appContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.content {
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.mapContainer {
  min-height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  background-color: var(--main-bg);

  position: relative;

  flex: 1;
}

.mapContainer_full {
  min-width: calc(100% - 50px);
  max-width: calc(100% - 50px);
}

.mapContainer_minimized {
  min-width: calc(100% - 420px);
  max-width: calc(100% - 420px);
}

.mapContainer>div {
  height: 100%;
}

.cesium-viewer-bottom {
  display: none;
}

.no-event-selected {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  position: absolute;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, .5);

  color: #fff;
}

.cesium-performanceDisplay-defaultContainer {
  right: unset;
  left: 10px;
  top: 10px;
}

@media (max-width: 968px) {
  .not_iOS .cesium-viewer-animationContainer,
  .not_iOS .cesium-viewer-timelineContainer {
    bottom: 55px !important;
  }

  .appContainer {
    flex-direction: column-reverse;
  }

  .mapContainer {
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: calc(55% - 60px);
    order: 3;
  }
}

@media (max-width: 968px) and (min-height: 375px) {

  .app_iOS .cesium-viewer-animationContainer,
  .app_iOS .cesium-viewer-timelineContainer {
    bottom: 105px !important;
  }
}

@media (max-width: 968px) and (max-height: 375px) {

  .app_iOS .cesium-viewer-animationContainer,
  .app_iOS .cesium-viewer-timelineContainer {
    bottom: 25px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5CD381;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2CA051;
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 999;

  background-color: rgba(3, 25, 30, .2);

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--main-accent);
}